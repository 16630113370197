.loginCard {
    margin-top: 20vh;
    background-color: var(--form-bg-color) !important;
}

.line{
    font-size: 1px;
}

.__height{
    max-height: 100vh !important;
    min-height: 100vh !important;
    /*background-image: url('../../assets/images/login__bg.jpg');*/
    background-size: 100% 100%;
    background-repeat: no-repeat;
}

.HeaderName{
    width: 40vh;
}