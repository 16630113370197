.contentMainMenu {
    height: 97vh;
    display: flex;
    align-items: center;
    background-color: var(--form-bg-color) !important;
}

.Bg {
    max-height: 100vh !important;
    min-height: 100vh !important;
    /*background-image: url('../../assets/images/login__bg.jpg');*/
    background-size: 100% 100%;
    background-repeat: no-repeat;
}